<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <h3>{{ dto.title }}</h3>
        <p v-html="dto.body"></p>

        <div style="float: right">
          <b-button v-on:click="onBackClicked">Back</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "parameter",
  mixins: [],
  components: {},
  data() {
    return {
      dto: {
        id: null,
        source: null,
        destination: null,
        priority: null,
        notificationStateId: null,
        notificationMethodId: null,
        title: null,
        body: null,
        fields: null,
        referenceObjectId: null,
      },

      showOverlay: true,
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Personal Notifications", route: "/personal-notifications" },
      { title: "Show" },
    ]);
  },

  created: async function () {
    if (this.$route.params.id != null) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/api/personal-notification/" + this.$route.params.id,
          requestType: "GET",
        })
        .then((response) => (this.dto = response))
        .catch(this.onError);
    }

    this.showOverlay = false;
  },

  methods: {
    onBackClicked: function () {
      this.$router.push({ name: "personal-notifications" });
    },
  },
};
</script>
